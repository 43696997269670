<template>
  <div class="home" v-if="homeData">
    <div class="swiperBox">
      <el-carousel trigger="click" arrow="always" height="800px" :autoplay="true" :interval="4000">
        <el-carousel-item v-for="(item, index) in homeData.banner" :key="index">
          <img class="swiperItem" :src="item.cover" alt />
          <!-- <img class="swiperItem" src="../assets/image/swiperItem.png" alt /> -->
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="container">
      <div class="box" v-for="(item, index) in homeData.index_goods" :key="index">
        <div class="boxImage-wrapper"><img class="boxImg" :src="item.top_images_src" alt /></div>
        <div class="boxInner">
          <div style="font-size: 24px;">{{$i18n.locale == 'zh'?item.goods_name:item.en_goods_name}}</div>
          <div v-html="$i18n.locale == 'zh'?item.short_description:item.en_short_description"
            style="margin-top: 20px;font-size:18px;color:#6e6e6e;line-height: 32px;"></div>
          <el-button style="font-size: 16px;" @click="toGoodsDetails(item.id)">
            {{$i18n.locale == "zh"?'了解更多':'learn more'}}</el-button>
        </div>
      </div>

      <div class="ArtistsBox">
        <div class="title">{{$i18n.locale == "zh"?'艺术家':'artist'}}</div>
        <div class="artistsList">
          <div class="artistsItem" v-for="(i,index) of homeData.index_artist" :key="index" @mouseover="nameIndex=index"
            @mouseleave="nameIndex=-1">
            <img class="artistsItemImg" :src="i.artist_avatars" alt />
            <div class="show-name" v-show="nameIndex != index">{{$i18n.locale == 'zh'?i.name:i.en_name}}</div>
            <div class="artistsItemHover">
              <div class="name">{{$i18n.locale == 'zh'?i.name:i.en_name}}</div>
              <div class="desc">
                <!-- <div class="desc-text" v-html="i.description"></div> -->
                <div class="rank_name" v-for="(item, index) in i.artist_ranks" :key="index">
                  {{$i18n.locale == 'zh'?item.rank_name:item.en_rank_name}}</div>

              </div>
              <button class="moreBtn" @click="toartdire(i.id)">{{$i18n.locale == "zh"?'查看详情':'view details'}} ></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      token: localStorage.getItem('token'),
      tabList: [{
        name: '关于我们',
        url: ''
      },
      {
        name: '产品服务',
        url: ''
      },
      {
        name: '影音资料',
        url: ''
      },
      {
        name: '社会责任',
        url: ''
      }
      ],
      windowWidth: 0, // 页面可视区域宽度
      homeData: '',
      selIndex: '',
      artData: '',
      nameIndex: -1
    }
  },
  methods: {
    toGoodsDetails(id) {
      this.$router.push(`Detail?id=${id}`)
    },
    // getArt() {
    // 	this.$axios.get('/artist')
    // 		.then(res => {
    // 			this.artData = res.data
    // 		})
    // },
    getHomeData() {
      this.$axios.get('/top_index')
        .then(res => {
          this.homeData = res.data
        })
    },
    // resize() {
    // 	this.windowWidth = window.innerWidth
    // 	if (this.windowWidth < 750) {
    // 		this.carouselHeight = '6.93rem'
    // 	} else {
    // 		this.carouselHeight = '800px'
    // 	}
    // },
    toartdire(id) {
      this.$router.push({
        path: `/artdire?id=${id}`
      })
    }
  },
  mounted() {
    // window.addEventListener('resize', this.resize)
    // this.windowWidth = window.innerWidth
    // if (this.windowWidth < 750) {
    // 	this.carouselHeight = '6.93rem'
    // } else {
    // 	this.carouselHeight = '800px'
    // }
    // this.getArt()
    this.getHomeData()
  }
}
</script>

<style lang="scss">
.swiperBox {
  min-width: 1280px;
  //   height: 800px;
  max-width: 1920px;
  margin: 0 auto;

  .swiperItem {
    width: 100%;
    height: 100%;
    background-color: #5179e7;
    object-fit: cover;
  }
}
.desc-text {
  width: 100%;
  word-break: break-all;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
.rank_name {
  width: 100%;
  word-break: break-all;
  padding: 4px 0;
}
.container {
  width: 1400px;
  margin: 50px auto 100px;

  .box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 50px;

    &:nth-child(2n) {
      // .boxImg:first-child {
      // 	display: none;
      // }
      flex-direction: row-reverse;
    }
    .boxImage-wrapper {
      width: 680px;
      height: 380px;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
    }
    .boxImg {
      width: 680px;
      height: 380px;
    }
    .boxInner {
      flex: 1;
      padding: 0 40px;
      // width: 560px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 500;
      color: #1f1f1f;

      .el-button {
        margin-top: 60px;
      }
      .el-button:hover {
        background-color: #1f1f1f;
        border-color: #1f1f1f;
        color: #fff;
      }
    }
  }

  .ArtistsBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 28px;
    font-family: Microsoft YaHei;
    font-weight: 500;
    color: #1f1f1f;

    .title {
      margin: 30px 0 0;
    }

    .artistsList {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .artistsItem {
        width: 332px;
        height: 402px;
        margin-top: 30px;
        position: relative;
        cursor: pointer;
        display: flex;
        justify-content: center;
        overflow: hidden;

        .artistsItemImg {
          width: auto;
          height: 100%;
        }

        &:hover .artistsItemHover {
          display: flex;
          animation-name: example;
          animation-duration: 0.6s;
        }
        @keyframes example {
          from {
            opacity: 0.1;
          }
          to {
            opacity: 1;
          }
        }
        .artistsItemHover {
          width: 100%;
          height: 100%;
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          background-color: rgba(31, 31, 31, 0.5);
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          position: absolute;
          left: 0;
          top: 0;
          text-align: center;
          display: none;

          .name {
            font-size: 24px;
            font-family: Microsoft YaHei;
            // font-weight: bold;
            color: #ffffff;
          }
          .desc {
            text-align: center;
            margin: 30px 0 40px;
            height: 90px;
            overflow: hidden;
            div {
              margin: 7px 0;
            }
          }

          .moreBtn {
            width: 180px;
            height: 50px;
            background: rgba(255, 255, 255, 0.5);
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 500;
            color: #1f1f1f;
            border: 0;
            outline: 0;
            border-radius: 5px;
            cursor: pointer;
          }
        }
      }
    }
  }
  .show-name {
    background: rgba(243, 243, 243, 0.8);
    padding: 3px 0;
    width: 60%;
    position: absolute;
    top: 270px;
    font-size: 22px;
    color: #1f1f1f;
    left: 50%;
    text-align: center;
    transform: translateX(-50%);
  }
}

.boxbot {
  margin-top: 10px;
}
.boxbom {
  margin-top: 10px;
}
</style>
